.profile{
  margin: 20px;
}

.button{
  margin-right: 20px !important;
}

.link{
  color: inherit;
  text-decoration: none;
  cursor: inherit;
}

.card{
  width: 400px;
}
