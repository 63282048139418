.home{
  margin: 20px;
}

.card{
  width: 95%;
  margin-bottom: 2%;
}
.search {
  position: relative;

}
