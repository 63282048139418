div.DraftEditor-root {
  background-color: rgba(233, 233, 232, 0.103);
  min-height: 200px;
  max-height: 500px;
  width: '100%';
  overflow-y: auto;
  border: 2.5px solid #ddd;
}
div.DraftEditor-editorContainer,
div.public-DraftEditor-content {
  min-height: 200px;
  height: 100%;
}