.profileModify{
  margin: 20px;
  text-align: left;
}

.title{
  padding-left: 30px;
  padding-top: 20px;
}

.content{
  margin: 10px;
  padding: 20px;
  padding-top: 0;
}

.link{
  margin-left: 20px;
  margin-right: 20px;
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.logo{
  margin-left: 0;
  margin-right: 0;
}
